.main-bg {
    /* background: darkslategray; */
    background-color: #0b1217 !important;
}

.trans-bg {
    background: transparent;
}

.content-bg {
    background-color: #142028 !important;
}

.flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}

.justify-around {
    justify-content: space-around;
}

.justify-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: end;
}

.items-center {
    align-items: center;
}

.align-right {
    text-align: right;
}

.w-full {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.w-80 {
    width: 80%;
}

.h-full {
    height: 100%;
}

.h-50vh {
    height: 50vh;
}

.h-70vh {
    height: 70vh;
}

.h-100vh {
    height: 100vh;
}

.br-10 {
    border-radius: 10px;
}

.text-white {
    color: white;
}

.text-grey {
    color: #737373;
}

.text-yellow {
    color: #F7931A;
}

.fs-12 {
    font-size: 12px;
}

.fs-20 {
    font-size: 20px;
}

.fs-24 {
    font-size: 24px;
}

.fs-28 {
    font-size: 28px;
}

.fs-32 {
    font-size: 32px;
}

.fs-36 {
    font-size: 36px;
}

.fs-40 {
    font-size: 40px;
}

.fs-48 {
    font-size: 48px;
}

.fs-60 {
    font-size: 60px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-30 {
    padding-top: 30px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-30 {
    padding-bottom: 30px;
}

.p-10 {
    padding: 10px;
}

.p-30 {
    padding: 30px;
}

.ml-20 {
    margin-left: 20px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-30 {
    margin-right: 30px;
}

.mt-30 {
    margin-top: 30px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.btn-yellow {
    background: #F7931A !important;
}

.input-border {
    border: 1px solid #F7931A;
    border-radius: 10px;
}